
<template>
  <vx-card>
    <div class="page">
      <!-- HEADER -->
      <!-- <vs-checkbox v-model="watermark">ลายน้ำรับรองว่าตรวจสอบทุกรายการ</vs-checkbox> -->
      <div v-if="forwho === 'c'" class="customer_label">ลูกค้า</div>
      <div v-if="forwho === 's'" class="staff_label">ร้านค้า</div>
      <div
        id="facility-card"
        class="grid-layout-container alignment-block"
        style="font-family: mitr; vertical-align:text-top ;width:100%;  padding: 30px; padding-top: 27px ; padding-bottom: 10px;"
      >
        <vs-row vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="4"
            vs-sm="4"
            vs-xs="4"
            style="font-size: 6 px !important;"
          >
            <!-- ชื่อผู้เช่า -->
            <vs-row vs-w="12" style="margin-top:5px;">
              <vs-col
                class="theLabel"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-lg="12"
                vs-sm="12"
                vs-xs="12"
              >
                ชื่อผู้เช่า: {{ this.customerName }}
                <br />
                เบอร์ติดต่อ : {{ this.customerPhone }}
                <br />
                ที่อยู่ : {{ this.customerAddress }}
                <br />
                <br />
                <div
                  style="width:100%;display:inline-block; font-size: 15px;"
                >กำหนดรับ : {{ format_ddmmyyy(this.startDate) }}</div>
                <br />
                <div
                  style="width:100%;display:inline-block; font-size: 15px;"
                >กำหนดคืน : {{ format_ddmmyyy(this.endDate) }}</div>
              </vs-col>
            </vs-row>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-lg="4"
            vs-sm="4"
            vs-xs="4"
          >
            <vs-row vs-w="12" vs-type="flex" vs-align="flex-start">
              <!-- ใบเสร็จรับเงิน -->
              <vs-row vs-w="12" vs-type="flex" vs-align="flex-start">
                <vs-col
                  class="theLabel"
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-lg="12"
                  vs-sm="12"
                  vs-xs="12"
                >
                  <div style="font-size: 20px; font-weight: bold;">{{taxInfo.receiptHeader}}</div>
                </vs-col>
              </vs-row>
              <!-- เบอร์ติดต่อ -->
              <vs-row vs-w="12" style="margin-top: 5px;">
                <vs-col
                  class="theLabel  px-2"
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-lg="12"
                  vs-sm="12"
                  vs-xs="12"
                >
                  เลข Order &nbsp;
                  <span class="ml-2" style="font-weight: light;">
                    {{
                    this.orderNumber
                    }}
                  </span>
                </vs-col>
              </vs-row>
              <!-- วันที่ออก -->
              <vs-row vs-w="12" style="margin-top: 5px;">
                <vs-col
                  class="theLabel"
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-lg="12"
                  vs-sm="12"
                  vs-xs="12"
                >วันที่ออก &nbsp; {{ format_ddmmyyy(orderInfo.orderHeader.createDateTime) }}</vs-col>
              </vs-row>
              <!-- Bracode value -->
              <vs-row vs-w="12" style="margin-top: 5px;">
                <vs-col
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-lg="12"
                  vs-sm="12"
                  vs-xs="12"
                >
                  <div>
                    <vue-barcode
                      style="width:100%;"
                      v-bind:value="orderNumber"
                      tag="img"
                    >ไม่มีบาร์โค้ด</vue-barcode>
                  </div>
                </vs-col>
              </vs-row>
            </vs-row>
          </vs-col>

          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="flex-start"
            vs-lg="4"
            vs-sm="4"
            vs-xs="4"
          >
            <vs-row vs-w="12" vs-type="flex" vs-align="flex-end" vs-justify="flex-end">
              <img style="width: 110px;" :src="theLogo()" />
            </vs-row>
            <vs-row
              style=" text-align:right;  font-weight: bold; "
              vs-w="12"
              vs-type="flex"
              vs-align="flex-end"
              vs-justify="flex-end"
              class="forprint"
            >{{shopinfo.name}} {{ aBranch.branchName }}</vs-row>

            <vs-row
              style="text-align:right;"
              vs-w="12"
              vs-type="flex"
              vs-align="flex-end"
              vs-justify="flex-end"
              class="forprint"
            >{{ aBranch.address }}</vs-row>

            <vs-row
              vs-w="12"
              vs-type="flex"
              vs-align="flex-end"
              vs-justify="flex-end"
              class="forprint"
            >โทร. {{ aBranch.phone }}</vs-row>
            <vs-row v-if="shopinfo.line !== null && shopinfo.line !==  undefined && shopinfo.line !==  ''" vs-w="12" vs-type="flex" vs-align="flex-end" vs-justify="flex-end">Line: {{shopinfo.line}} </vs-row>
            <vs-row
              vs-w="12"
              vs-type="flex"
              vs-align="flex-end"
              vs-justify="flex-end"
              class="forprint"
            >เปิด {{ aBranch.workingTime }}</vs-row>
          </vs-col>
        </vs-row>
      </div>
      <div
        v-if="watermark === true"
        style="position:absolute; margin-top:50px ; margin-left: 20%;width: 50%; z-index: 999;"
      >
        <img style=" width: 100%;" :src="alreadycheckedImage()" />
      </div>

      <!-- ตาราง -->
      <div v-for="(ii, ind) in looplist" :key="ind">
        <vs-table hoverFlat stripe :data="ii">
          <template slot="thead">
            <vs-th>รูปตัวอย่าง</vs-th>
            <vs-th style="width:200px;" sort-key="itemName">ชื่อสินค้า</vs-th>
            <vs-th sort-key="reservedQuantity">จำนวน</vs-th>
            <vs-th style="text-align:center;" sort-key="rentalPrice">ราคาเช่า(บาท)</vs-th>
            <vs-th style="text-align:center;" sort-key="bail">เงินประกัน(บาท)</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr class="table-font" :data="tr" :key="indextr" v-for="(tr, indextr) in ii">
              <vs-td>
                <div
                  class="grid-layout-container alignment-block"
                  style="vertical-align:text-top ;width:100%;"
                >
                  <vs-row vs-w="12">
                    <vs-col
                      vs-type="flex"
                      vs-justify="flex-start"
                      vs-align="center"
                      vs-lg="6"
                      vs-sm="6"
                      vs-xs="6"
                    >
                    <div style="height:40px;  overflow-y:hidden !important;  overflow-x:hidden !important;" >
                      <img
                        class="responsive rounded"
                        :src="getImagepath(data[indextr].mainImage)"
                        alt="user-upload"
                        style="height:40px; width:auto;"
                      />
                    </div>


                    </vs-col>
                    <vs-col
                      vs-type="flex"
                      vs-justify="flex-start"
                      vs-align="center"
                      vs-lg="6"
                      vs-sm="6"
                      vs-xs="6"
                    >

                    </vs-col>
                  </vs-row>
                </div>
              </vs-td>

              <vs-td :data="data[indextr].name">
                {{ data[indextr].name }}
                <br />

                <span style="font-size:9px;">({{ data[indextr].code }})</span>
                <br />
                <i
                  v-if="getRemoved(indextr) === 'y'"
                  class="vs-icon notranslate icon-scale vs-button--icon material-icons null"
                  style="order: 0;text-align:left;  font-size:25px; color:red; cursor:pointer;"
                >cancel</i>
                <span v-if="getRemoved(indextr) === 'k'" style="font-size:0.75rem; color:blue;" >(มูลค่าของรายการนี้ถูกเก็บสิทธิ์)</span>

              </vs-td>

              <vs-td :data="data[indextr].reservedQuantity">
                {{
                data[indextr].reservedQuantity
                }}
              </vs-td>

              <vs-td :data="data[indextr].rentalPrice">
                <span style="padding:14px 10px;">
                  {{
                  formatPrice(
                  data[indextr].rentalPrice * data[indextr].reservedQuantity
                  )
                  }}
                </span>
              </vs-td>

              <vs-td :data="data[indextr].bail">
                <span style="padding:14px 10px;">
                  {{
                  formatPrice(
                  data[indextr].bail * data[indextr].reservedQuantity
                  )
                  }}
                </span>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <vs-row
          style="font-size:9px;"
          v-if="thepage > 1"
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="flex-end"
        >หน้า {{ ind + 1 }}</vs-row>
        <div v-if="ind + 1 <= thepage - 1" class="pagebreak"></div>
      </div>

      <div v-if="signnextpage" class="pagebreak"></div>
      <!-- BEGIN: MONETARY SUMMATION -->
    <div class="grid-layout-container alignment-block" style="width:100%; margin-top: 20px;">
      <vs-row vs-w="12"  >
        <vs-col v-if="shopinfo.termAndCondition !== null && shopinfo.termAndCondition !== undefined && shopinfo.termAndCondition !== ''" style="border: solid 2px red;" vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-lg="8" vs-sm="8" vs-xs="8">
          <div
             v-if="shopinfo.termAndCondition"
            style="padding: 10px; font-family: mitr; color:black ; font-size: 0.75rem !important;"
            v-html="shopinfo.termAndCondition"
          ></div>
        </vs-col>
        <vs-col v-else  vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-lg="8" vs-sm="8" vs-xs="8">

        </vs-col>
        <vs-col  vs-type="flex" vs-justify="center" vs-align="center" vs-lg="4" vs-sm="4" vs-xs="4">
          <div class="grid-layout-container alignment-block" style="width:100%; margin-top: 20px;">
             <!-- ยอดรวมตามรายการ -->
             <vs-row   vs-w="12" style="padding: 0 5px; " class="grand-total the-black">
               <vs-col
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="center"
                vs-lg="5"
                vs-sm="5"
                vs-xs="5"
                style=" font-size:0.9rem; "
              >ยอดเช่ารวม</vs-col>
              <vs-col
                style="padding-left: 10px; font-size:0.9rem; "
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-lg="7"
                vs-sm="7"
                vs-xs="7"
              >{{ formatPrice(rentalPriceTotal) }} <span class="pl-2" style="font-size: 0.5rem;"> บาท</span> </vs-col>
              </vs-row>
               <!-- ส่วนลดค่าเช่า -->
            <vs-row
              v-if="
                rentalDiscount > 0 &&
                  rentalDiscount != undefined &&
                  rentalDiscount != null
              "
              vs-w="12"
              style="padding: 0 5px; "
              class="grand-total-discount the-red"
            >
              <vs-col
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="center"
                vs-lg="5"
                vs-sm="5"
                vs-xs="5"
                style="font-size:0.9rem;"
              >ส่วนลดค่าเช่า</vs-col>
              <vs-col
                style="padding-left: 10px; font-size:0.9rem; "
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-lg="7"
                vs-sm="7"
                vs-xs="7"
              >{{ formatPrice(rentalDiscount) }} <span class="pl-2" style="font-size: 0.5rem;"> บาท</span> </vs-col>

            </vs-row>

             <!-- ภาษีมูลค่าเพิ่ม -->
            <vs-row  v-if="vatSystem===true" vs-w="12" style="padding: 0 5px; " class="grand-total the-black">
              <!-- VAT  -->
              <!-- 2021-04-07 -->

              <vs-col
               style="font-weight: 200; font-size:0.9rem;"
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="center"
                vs-lg="5"
                vs-sm="5"
                vs-xs="5"
              >VAT ({{taxRate}}%)
              </vs-col>

              <!-- <vs-col
                style="padding-left: 10px; font-weight: 200 !important; font-size:0.9rem; "
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
              >
              {{ formatPrice(vat_final(rentalPriceTotal, rentalDiscount)) }} </vs-col> -->

               <vs-col
                style="padding-left: 10px; font-weight: 200 !important; font-size:0.9rem; "
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-lg="7"
                vs-sm="7"
                vs-xs="7"
              >
              {{ formatPrice(vat) }} <span class="pl-2" style="font-size: 0.5rem;"> บาท</span> </vs-col>


            </vs-row>

            <!-- Before VAT -->
             <vs-row  v-if="vatSystem===true" vs-w="12" style="padding: 0  20px; " class="grand-total the-black">

              <vs-col
              style="font-weight: 200 !important; font-size:0.9rem; "
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="center"
                vs-lg="5"
                vs-sm="5"
                vs-xs="5"
              >Before VAT</vs-col>

              <vs-col
                style="padding-left: 10px; font-weight: 200 !important; font-size:0.9rem; "
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-lg="7"
                vs-sm="7"
                vs-xs="7"
              >{{ formatPrice(beforeVat) }} <span class="pl-2" style="font-size: 0.5rem;"> บาท</span> </vs-col>
            </vs-row>


             <!-- รวมค่าเช่า -->
            <vs-row  v-if="vatSystem===true"   vs-w="12" style="padding: 0 5px; " class="grand-total the-black">
              <!-- GRAND TOTAL MONETARY SUMMATION -->

              <vs-col
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="center"
                vs-lg="5"
                vs-sm="5"
                vs-xs="5"
                style=" font-size:1.1rem;  "
              >ยอดเช่าสุทธิ</vs-col>

              <!-- <vs-col

                style="padding-left: 10px; font-size:1.1rem;  "
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
              >{{ formatPrice(rentalPriceTotal_final(rentalPriceTotal, rentalDiscount)) }} </vs-col> -->
              <vs-col

                style="padding-left: 10px; font-size:1.1rem;  "
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-lg="7"
                vs-sm="7"
                vs-xs="7"
              >{{ formatPrice(netPrice) }} <span class="pl-2" style="font-size: 0.5rem;"> บาท</span> </vs-col>

            </vs-row>



            <!-- ขีดเส้นใต้ -->
            <vs-row vs-w="12" style="height: 10px !important;">
              <vs-col
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="center"
                vs-lg="5"
                vs-sm="5"
                vs-xs="5"
                style="border-bottom: 1px solid #f4f4f4; "
              >&nbsp;</vs-col>
              <vs-col
                style="padding-left: 10px; border-bottom: 1px solid #f4f4f4; "
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-lg="7"
                vs-sm="7"
                vs-xs="7"
              >&nbsp;</vs-col>
            </vs-row>
            <!-- รวมค่าประกัน -->
            <vs-row vs-w="12" style="padding: 0 5px; " class="grand-total the-black">
              <vs-col
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="center"
                vs-lg="5"
                vs-sm="5"
                vs-xs="5"
                style=" padding-top: 10px; font-size: 0.9rem;"
              >ยอดรวมค่าประกัน</vs-col>
              <vs-col
                style="padding-left: 10px;  padding-top: 10px; font-size: 0.9rem;"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-lg="7"
                vs-sm="7"
                vs-xs="7"
              >{{ formatPrice(bailTotal) }} <span class="pl-2" style="font-size: 0.5rem;"> บาท</span> </vs-col>
            </vs-row>
            <!-- ส่วนลดค่าประกัน -->
            <vs-row
              v-if="
                bailDiscount > 0 &&
                  bailDiscount != undefined &&
                  bailDiscount != null
              "
              vs-w="12"
              style="padding: 0 10px; "
              class="grand-total-discount the-red"
            >
              <vs-col
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="center"
                vs-lg="5"
                vs-sm="5"
                vs-xs="5"
                style=" font-size:0.9rem;  "
              >ส่วนลดค่าประกัน</vs-col>
              <vs-col
                style="padding-left: 10px;  font-size:0.9rem; "
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-lg="7"
                vs-sm="7"
                vs-xs="7"
              >{{ formatPrice(bailDiscount) }} <span class="pl-2" style="font-size: 0.5rem;"> บาท</span> </vs-col>
            </vs-row>

            <!-- โปรโมชั่น -->
            <vs-row
              v-if="
                promotionDiscount > 0 &&
                  promotionDiscount != undefined &&
                  promotionDiscount != null
              "
              vs-w="12"
              style="padding: 0 5px;"
              class="grand-total-discount the-red"
            >
              <vs-col
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="center"
                vs-lg="5"
                vs-sm="5"
                vs-xs="5"
                style="  "
              >โปรโมชั่น</vs-col>
              <vs-col
                style="padding-left: 10px;   "
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-lg="7"
                vs-sm="7"
                vs-xs="7"
              >{{ formatPrice(promotionDiscount) }} <span class="pl-2" style="font-size: 0.5rem;"> บาท</span></vs-col>
            </vs-row>
            <!-- ขีดเส้นใต้ -->
            <!-- ยอดรวมทั้งหมด -->
            <vs-row
              vs-w="12"
              style="padding: 0 5px; margin-top:10px; "
              class="grand-total the-blue"
            >
              <vs-col
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="center"
                vs-lg="5"
                vs-sm="5"
                vs-xs="5"
              >ยอดรวมทั้งหมด</vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-lg="7"
                vs-sm="7"
                vs-xs="7"
                style="padding-left:10px;"
              >{{ formatPrice(grandTotal) }} <span class="pl-2" style="font-size: 0.5rem;"> บาท</span> </vs-col>
            </vs-row>

            <!-- ขีดเส้นใต้ -->
            <vs-row vs-w="12" style="height: 10px !important;">
              <vs-col
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="center"
                vs-lg="5"
                vs-sm="5"
                vs-xs="5"
                style="border-bottom: 4px solid #f0f0f0; padding:0;height:20px;"
              >&nbsp;</vs-col>
              <vs-col
                style="border-bottom: 4px solid #f0f0f0;  padding-left: 10px;  padding:0;height:20px;"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-lg="7"
                vs-sm="7"
                vs-xs="7"
              >&nbsp;</vs-col>
            </vs-row>
            <!-- เว้นช่องไฟ -->
            <vs-row vs-w="12" style="margin-top:20px; height: 20px !important;">
              <vs-col
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="center"
                vs-lg="12"
                vs-sm="12"
                vs-xs="12"
                style=" padding:0;height:15px; font-size: 0.75rem;"
              >
              <span v-if="voucherDiscount > 0 && voucherDiscount != undefined && voucherDiscount != null" >ส่วนลด voucher:<br>  {{ formatPrice(voucherDiscount) }}</span>
                <span v-if="pointDiscount > 0 && pointDiscount != undefined && pointDiscount != null" class="ml-2">&nbsp;ใช้พอยต์:<br>  {{ formatPrice(pointDiscount) }}</span>
                 <span v-if="getPoint > 0 && getPoint != undefined && getPoint != null" class="ml-2">&nbsp;ได้พอยต์:<br>  {{ formatPrice(getPoint) }}</span>
            </vs-col>

            </vs-row>



          </div>
        </vs-col>
      </vs-row>
      <vs-row style="margin-top:10px;" vs-w="12">
        <i
          style="font-size:20px; margin-left:5px; margin-right:5px;vertical-align:middle;  border-radius:10px; "
          class="material-icons iconhover"
        >local_shipping</i>
        วิธีรับชุด : {{ receivingMethod }}
      </vs-row>
      <vs-row style="margin-top:5px;" vs-w="12">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-lg="7"
          vs-sm="7"
          vs-xs="12"
        >
          หมายเหตุ :
          {{ remark1 }}
        </vs-col>
      </vs-row>
    </div>

      <!-- END: MONETARY SUMMATION -->

      <!--------------------------------------------------------------------->
      <!-- ลายเซ็น -->
      <!--------------------------------------------------------------------->
      <div
        v-if="receiptInfo.settlementBox"
        class="grid-layout-container alignment-block"
        style="vertical-align:text-top ;width:100%; margin-top:0;margin-bottom:0px;"
      >
        <vs-row vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-lg="4"
            vs-sm="4"
            vs-xs="12"
          >
            <div
              style="border: 1px solid #979797 ; border-radius: 5px; width: 95%; min-height: 70px;"
            >
              <vs-row vs-w="12" vs-type="flex" vs-justify="center">
                <div style="font-family:mitr ; font-size: 14px;">ค่าเช่าชุด</div>
              </vs-row>
              <vs-row vs-w="12" vs-type="flex" vs-justify="center">
                <div v-if="this.netPrice > 0 || this.netPrice != null"
                style="font-family:mitr ; font-size: 12px;"
              >ชำระ: {{ formatPrice(this.netPrice) }} บาท
              </div>
              <div v-else
                style="font-family:mitr ; font-size: 12px;"
              >ชำระ: {{ formatPrice(this.theLastTotalPrice()) }} บาท
              </div>
              </vs-row>
              <vs-row vs-w="12" vs-type="flex" vs-justify="center">
                <img style="width:90%;" :src="rentalImage()" />
              </vs-row>
            </div>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-lg="4"
            vs-sm="4"
            vs-xs="12"
          >
            <div
              style="border: 1px solid #979797 ; border-radius: 5px; width: 95%;  min-height: 70px;"
            >
              <vs-row vs-w="12" vs-type="flex" vs-justify="center">
                <div style="font-family:mitr ; font-size: 14px;">ค่าประกัน</div>
              </vs-row>
              <vs-row vs-w="12" vs-type="flex" vs-justify="center">
              <div v-if="this.bailNetTotal > 0 || this.bailNetTotal != null"
                style="font-family:mitr ; font-size: 12px;"
              >ชำระ: {{ formatPrice(this.bailNetTotal) }} บาท</div>
              <div v-else
                style="font-family:mitr ; font-size: 12px;"
              >ชำระ: {{ formatPrice(this.theLastBail()) }} บาท</div>
              </vs-row>
              <vs-row vs-w="12" vs-type="flex" vs-justify="center">
                <img style="width:90%;" :src="rentalImage()" />
              </vs-row>
            </div>
          </vs-col>
         <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="4" vs-sm="4" vs-xs="12">
          <div
            style="border: 1px solid #979797 ; border-radius: 5px; width: 95%; "
          >
            <vs-row vs-w="12" vs-type="flex" vs-justify="center">
              <div style="font-family:mitr ; font-size: 14px;">คืนเงินประกัน</div>
            </vs-row>
            <vs-row vs-w="12" vs-type="flex" vs-justify="center">
              <div
                style="font-family:mitr ; font-size: 12px;"
              >ชำระ: . . . . . . . . . . .</div>
            </vs-row>
            <vs-row vs-w="12" vs-type="flex" vs-justify="center">
              <img style="width:90%;" :src="returnImage()" />
            </vs-row>
          </div>
          <!-- -->
        </vs-col>
        </vs-row>
        <vs-row vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-lg="4"
            vs-sm="4"
            vs-xs="12"
          ></vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-lg="4"
            vs-sm="4"
            vs-xs="12"
          ></vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="4"
            vs-sm="4"
            vs-xs="12"
          >

          </vs-col>
        </vs-row>
        <vs-row
          style="font-size:9px;"
          v-if="thepage > 1"
          vs-w="12"
          vs-type="flex"
          vs-justify="flex-end"
        >หน้า {{ thepage }}</vs-row>
        <vs-row v-if="thepage > 1" vs-w="12" vs-type="flex" vs-justify="center">
          <!-- วันที่ออก -->
          <vs-row vs-w="12" style="margin-top: 5px;">
            <vs-col
              class="theLabel"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="12"
              vs-sm="12"
              vs-xs="12"
            >วันที่ออก &nbsp; {{ formatDate(this.issuedDate) }}</vs-col>
          </vs-row>
          <!-- Bracode value -->
          <vs-row vs-w="12" style="margin-top: 5px;">
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="12"
              vs-sm="12"
              vs-xs="12"
            >
              <div>
                <vue-barcode style="width:100%;" v-bind:value="orderNumber" tag="img">ไม่มีบาร์โค้ด</vue-barcode>
              </div>
            </vs-col>
          </vs-row>
        </vs-row>
      </div>

      <!--------------------------------------------------------------------->
      <!--------------------------------------------------------------------->
      <!--------------------------------------------------------------------->

      <!--------------------------------------------------------------------->
    </div>
  </vx-card>
</template>

<script>
import Prism from "vue-prism-component";
import Datepicker from "vuejs-datepicker";
import { Validator } from "vee-validate";
import VueBarcode from "vue-barcode";

import shapeFormat from "../../mixins/shapeFormat.js";

// import jsPDF from "jspdf";

// import html2canvas from "html2canvas";

const dict = {
  custom: {
    name: {
      required: "ยังไม่ได้ใส่ชื่อลูกค้า"
    }
  }
};

Validator.localize("en", dict);

export default {
  mixins: [shapeFormat],
  props: {
    orderid: String,
    watermark: Boolean,
    forwho: String
  },
  data() {
    return {
      // shopInfo: {},
      lastPageNum: 0,
      detail: [],
      aBranch: {},
      // watermark: true,
      startDate: null,
      endDate: null,

      orderHeaderId: "",
      issuedDate: new Date(),
      displayItems: [],
      branchStock: [],
      itemsInBranch: [],
      // 1. ORDER HEADER
      orderNumber: "",
      orderStatus: 0, // 0: darft, 1: saved, 2: waiting for receiving, 3: waiting for returning, 4: waiting for money back, 5: closed
      remark: "",
      remark1: "",

      // 2. CUSTOMER
      customerInfo: "",
      customerName: "",
      customerAddress: "",
      customerPhone: "",
      accountNumber: "",
      accountName: "",
      bankName: "",
      transferContact: "",

      // 3. DELIVERY
      howtoreceive: "มารับเอง", // 1 :มารับเอง, 2: ผ่านเมสเซนเจอร์, 3: ผ่าน EMS / Kerry
      receivingDate: null,
      returnDate: null,
      addtocart: "",
      receivingMethod: "",

      // 4. MONETARY
      rentalPriceTotal: 0,
      bailTotal: 0,
      grandTotal: 0,
      bailDiscount: 0,
      rentalDiscount: 0,
      promotionDiscount: 0,
      rentalDiscountPercent: 0,
      bailDiscountPercent: 0,
      //*** BEGIN 15Nov2021 เพิ่มภาษี */
      voucherDiscount: 0,
      pointDiscount: 0,
      netPrice: 0 ,
      vat: 0,
      beforeVat: 0,
       vatSystem: false,
      inVat: false,
      getPoint: 0,
      taxRate: 7,
      //*** END 15Nov2021 เพิ่มภาษี */


      deduction: 0,
      deducingDate: null,

      // 5. LIST
      originalList: [],
      select_order: "OR1903130042",
      order_option: [
        { text: "OR1903130042", value: "OR1903130042" },
        { text: "OR1903130041", value: "OR1903130041" },
        { text: "OR1903130040", value: "OR1903130040" }
      ],
      orders: [],
      countOrder: 0,
      shopinfo: {
        name: "",
        phone: "",
        line: "",
        termAndCondition: ""
      },
      looplist: [],
      thepage: 1,
      allPages: 1,
      signnextpage: false,
      orderInfo: {},


    };
  },
  async mounted() {
    // this.theLogo = this.$store.state.defaultStore + "logo.png";
    this.orderHeaderId = this.orderid;
    // var wm = this.$route.params.watermark;

    // if (wm === "f") {
    //   this.watermark = false;
    // } else {
    //   this.watermark = true;
    // }

    // this.currentUser = JSON.parse(localStorage.getItem("username"));

    // BEGIN >>>>>>> INITAILZE ITEMS IN BRANCH : params : {thisBranch} >>>>>>>>>

    // await this.comeback(this.orderHeaderId);
    // this.orders = await this.displayItems;

    // var resShop = await this.loadShopInfo();

    // if (resShop !== null) {
    //   this.shopinfo = await resShop.data;
    // }

    // this.countOrder = await this.orders.length;

    // var pagecount = await this.countPage(this.orders);


    // console.log("orderHeaderId @@@@ >>> ", this.orderHeaderId);
  },
  methods: {
    getRemoved(ind) {
      return this.detail[ind].flag1;
    },
    addlooplist(displaylist) {
      // var page;
      const first = 5;
      const lastpage = 5;
      const eachpage = 10;
      var pageNum = 1;
      const list_length = displaylist.length;
      // const list_length = 45;

      // if (list_length > first && list_length <= first + eachpage) {
      //   page = 2;
      // } else if (list_length > first + eachpage) {
      //   page = Math.ceil((list_length - first) / eachpage);
      // }

      var alllist = [];
      var list1 = [];
      // var list2 = [];
      // var list3 = [];

      let first_length = 0;
      var second = false;
      if (list_length <= first) {
        first_length = list_length;
        this.thepage = 1;
      } else if (list_length > first && list_length <= eachpage) {
        first_length = list_length;
        this.thepage = 2;
        this.signnextpage = true;
      } else {
        first_length = eachpage;
        // set ให้จำนวนหน้าแรกเท่ากับ eachpage = 20
        pageNum = Math.floor(list_length / eachpage);
        // set last number มากกว่า จำนวนหน้าสุดท้ายที่ set ไว้ = 12 ให้ย้ายลายเซ็นไปไว้อีกหน้า

        if (list_length - pageNum * eachpage <= 0) {
          this.lastPageNum = eachpage;
          this.thepage = pageNum;
        } else {
          this.lastPageNum = list_length - pageNum * eachpage;
          this.thepage = pageNum + 1;
        }

        if (this.lastPageNum > lastpage) {
          this.signnextpage = true;
        }

        second = true;
      }

      // console.log("The PAGEESSSSSS >>>> ", this.thepage);

      for (var i = 0; i < first_length; i++) {
        list1[i] = displaylist[i];
      }

      alllist.push(list1);

      if (second === true) {
        for (var b = 1; b < this.thepage; b++) {
          var listg = [];
          var pagenumber = eachpage;
          if (b === this.thepage - 1) {
            pagenumber = this.lastPageNum;
          }
          for (var j = 0; j < pagenumber; j++) {
            listg[j] = displaylist[j + b * eachpage];
          }
          alllist.push(listg);
        }

        // console.log("###### second === true alllist >>>>>>>>>>> ", alllist);
        // console.log(
        //   "###### second === true first_length >>>>>>>>>>> ",
        //   first_length
        // );
      }

      // this.looplist = alllist;

      // console.log("list_length >>>>> ", list_length);
      // console.log("LIST 1 >>>>> ", list1);
      // console.log("LIST 2 >>>>> ", list2);
    // console.log("ALL LIST  >>>>> ", this.looplist);
      return alllist;
    },

    async loadShopInfo() {
      var res = null;
      try {
        res = await this.$http.get(
          this.$store.state.apiURL + "/api/shopinfo",
          this.$store.state.auth.apiHeader
        );
      } catch (err) {
        res = null;
      }

      return res;
    },
   theLastTotalPrice() {
      var rd = isNaN(Number(this.rentalDiscount))
        ? 0
        : Number(this.rentalDiscount);

      var pd = isNaN(Number(this.promotionDiscount))
        ? 0
        : Number(this.promotionDiscount);

      var total = isNaN(Number(this.rentalPriceTotal))
        ? 0
        : Number(this.rentalPriceTotal);

      // console.log("total : ", total, " pd : ", pd, " rd : ", rd);

      if(this.taxInfo.vatSystem === false)
        return total - (pd + rd);
      else{
        return this.rentalPriceTotal_final(this.rentalPriceTotal, this.rentalDiscount);
      }

    },
    theLastBail() {
      var bd = isNaN(Number(this.bailDiscount)) ? 0 : Number(this.bailDiscount);

      var total = isNaN(Number(this.bailTotal)) ? 0 : Number(this.bailTotal);
      return total - bd;
    },
    conditionImage() {
      return this.$store.state.defaultStore + "condition.png";
    },
    alreadycheckedImage() {
      return this.$store.state.defaultStore + "alreadychecked.png";
    },
    rentalImage() {
      return this.$store.state.defaultStore + "rental.png";
    },
    bailImage() {
      return this.$store.state.defaultStore + "bail.png";
    },
    returnImage() {
      return this.$store.state.defaultStore + "return.png";
    },

    backtorental() {
      this.$router.push("/backoffice/rental/" + this.orderHeaderId);
    },

    theLogo() {
      // console.log('this.shopinfo.logo >>> ',this.shopinfo.logo);
      if (
        this.shopinfo.logo === undefined ||
        this.shopinfo.logo === "" ||
        this.shopinfo.logo === null
      ) {
        return this.$store.state.defaultStore + "logo.png";
      } else {
        return this.$store.state.defaultStore  + this.shopinfo.logo;
      }
    },

    // async loadProduct() {
    //   var response;
    //   try {
    //     response = await this.$http.get(
    //       this.$store.state.apiURL + "/api/productitem",
    //       this.$store.state.auth.apiHeader
    //     );
    //     if (response.status === 200) {
    //       this.branchStock = await response.data;
    //     }
    //   } catch (error) {
    //     this.noticeError(error);
    //   }

    //   return await response.data;
    // },
    // async loadStock(branchId) {
    //   var response;
    //   try {
    //     response = await this.$http.get(
    //       this.$store.state.apiURL + "/api/productitem/branch/" + branchId,
    //       this.$store.state.auth.apiHeader
    //     );
    //     if (response.status === 200) {
    //       this.branchStock = await response.data;
    //     }
    //   } catch (error) {
    //     this.noticeError(error);
    //     // console.log("error", error);
    //   }

    //   return await response.data;
    // },
    // >>>>>> เราต้องการ this.displayItems
    async comeback(orderid) {

      var list = [];
      // this.orderHeaderId = this.$route.params.orderid;
      // this.currentUser = JSON.parse(localStorage.getItem("username"));

      var resp = null;
      try {
        resp = await this.$http.post(
          this.$store.state.apiURL + "/api/orderheader/receipt-info/" + orderid,
          {},
          this.$store.state.auth.apiHeader
        );
        this.orderInfo = resp.data;

        this.currentBranchId =  this.orderInfo.orderHeader.branchId;

        this.orderNumber =  this.orderInfo.orderHeader.orderNumber;
        this.customerName =  this.orderInfo.orderHeader.customerName;
        this.customerAddress =  this.orderInfo.orderHeader.customerAddress;
        this.customerPhone =  this.orderInfo.orderHeader.customerPhone;
        this.receivingMethod =  this.orderInfo.orderHeader.receivingMethod;
        this.startDate =  this.orderInfo.orderHeader.pickupDate;
        this.endDate =  this.orderInfo.orderHeader.returnDate;
        this.rentalPriceTotal =  this.orderInfo.orderHeader.rentalPriceTotal;
        this.bailTotal =  this.orderInfo.orderHeader.bailTotal;
        this.grandTotal =  this.orderInfo.orderHeader.grandTotal;
        this.rentalDiscount =  this.orderInfo.orderHeader.rentalDiscount;
        this.bailDiscount =  this.orderInfo.orderHeader.bailDiscount;
        this.promotionDiscount =  this.orderInfo.orderHeader.promotionDiscount;
        this.orderStatus =  this.orderInfo.orderHeader.orderStatus;
        this.remark1 =  this.orderInfo.orderHeader.remark1;

        //*** BEGIN 15Nov2021 เพิ่มภาษี */
        this.voucherDiscount = this.orderInfo.orderHeader.voucherDiscount;
        this.pointDiscount = this.orderInfo.orderHeader.pointDiscount;
        this.netPrice = this.orderInfo.orderHeader.netPrice;
        this.vat = this.orderInfo.orderHeader.vat;
        this.beforeVat = this.orderInfo.orderHeader.beforeVat;
        this.inVat = this.orderInfo.orderHeader.inVat;
        this.vatSystem = this.orderInfo.orderHeader.vatSystem;
        this.getPoint = this.orderInfo.orderHeader.getPoint;
        this.taxRate = this.orderInfo.orderHeader.taxRate;
         //*** END 15Nov2021 เพิ่มภาษี */

        this.detail =  this.orderInfo.orderDetail;
        var dlist =  this.orderInfo.orderDetail;


        for (var d = 0; d < dlist.length; d++) {
          var theItm = {};
          theItm  = Object.assign({}, dlist[d])
          theItm.removed =  dlist[d].flag1;
          this.displayItems.push(theItm);
          this.orders.push(theItm);
          list.push(theItm);
        }

        // this.shopInfo = this.orderInfo.shopInfo;
        this.shopinfo = this.orderInfo.shopInfo;

        this.aBranch = this.orderInfo.branch;


      } catch (err) {
        this.$swal({
          icon: 'warning',
          title: 'ไม่สามารถรับข้อมูลได้',
        });
      }



      return list;
    },

    async loadOrder(orderid) {
      var response;
      try {
        response = await this.$http.get(
          this.$store.state.apiURL + "/api/orderheader/" + orderid,
          this.$store.state.auth.apiHeader
        );
      } catch (error) {
        this.noticeError(error);
      }

      return await response.data;
    },

    async loadDetail(orderid) {
      var response;
      try {
        response = await this.$http.get(
          this.$store.state.apiURL + "/api/orderdetail/" + orderid,
          this.$store.state.auth.apiHeader
        );
      } catch (error) {
        this.noticeError(error);
      }

      return await response.data;
    },

    totalRental: function() {
      var total = 0;
      for (var i = 0; i < this.orders.length; i++) {
        total += this.orders[i].rentalPrice;
      }
      return total;
    },
    // goback
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    setFocus: function() {
      // Note, you need to add a ref="search" attribute to your input.
      // this.$refs.addcart.$el.focus();
    },

    lineTotal: function(rentalPrice, bail) {
      if (!Number.isFinite(rentalPrice)) rentalPrice = 0;
      if (!Number.isFinite(bail)) rentalPrice = 0;
      return rentalPrice + bail;
    },
    removeItem: function(indextr) {
      this.orders[indextr].reservedQuantity = 1;
      this.orders.splice(indextr, 1);
      // console.log(this.orders);
    },
    setSelect: function() {
      event.target.setSelectionRange(0, this.addtocart.length);
    }
  },
  async created() {
    // var rp = this.$route.params.orderno;
    // alert(rp);

    // INITIALIZE DATA
 await this.$store.dispatch('shop/fetch');
    // 1. ORDER HEADER
    this.orderStatus = 0; // 0: darft, 1: saved, 2: waiting for receiving, 3: waiting for returning, 4: waiting for money back, 5: closed

    // 2. CUSTOMER
    this.customerInfo = "";
    this.customerName = "";
    this.customerAddress = "";
    this.customerPhone = "";

    // 3. DELIVERY
    this.howtoreceive = "มารับเอง"; // 1 :มารับเอง, 2: ผ่านเมสเซนเจอร์, 3: ผ่าน EMS / Kerry
    this.receivingDate = null;
    this.returnDate = null;
    this.addtocart = "";

    // 4. MONETARY

    // this.bailDiscount = 300;
    // this.rentalDiscount = 100;
    this.promotionDiscount = 0;

    this.rentalPriceTotal = 0;
    this.bailTotal = 0;
    this.grandTotal = 0;

    this.orderHeaderId = this.orderid;
    this.currentUser = JSON.parse(localStorage.getItem("username"));

    // BEGIN >>>>>>> INITAILZE ITEMS IN BRANCH : params : {thisBranch} >>>>>>>>>

     await this.comeback(this.orderHeaderId);

    this.looplist = await this.addlooplist(this.orders);


    // for (var i = 0; i < this.orders.length; i++) {
    //   this.rentalPriceTotal =
    //     this.rentalPriceTotal +
    //     this.orders[i].rentalPrice * this.orders[i].reservedQuantity;

    //   this.bailTotal =
    //     this.bailTotal + this.orders[i].bail * this.orders[i].reservedQuantity;
    // }
    // this.grandTotal = this.grandTotal + this.rentalPriceTotal + this.bailTotal;

    // console.log("this.grandTotal >>> ", this.grandTotal);
  },
  components: {
    Prism,
    Datepicker,
    VueBarcode
  },

  computed: {
    //  *** BEGIN : 2020-04-06 : TAX INFO
    taxInfo(){
      return this.$store.getters["shop/tax"];
    },
    receiptInfo(){
      console.log("this.$store.getters[shop/receipt] >>> ",this.$store.getters["shop/receipt"]);
      return this.$store.getters["shop/receipt"];
    },
  },
  watch: {
    // // addtocart: function(newvalue) {
    // //   var newItem = this.itemsInStock.find(function(ele) {
    // //     if (ele.itemName === newvalue) {
    // //       return ele;
    // //     }
    // //   });
    // //   if (newItem) {
    // //     event.target.setSelectionRange(0, this.addtocart.length);
    // //     var foundindex = this.displayItems.findIndex(
    // //       x => x.itemName === newItem.itemName
    // //     );
    // //     if (foundindex < 0) this.displayItems.push(newItem);
    // //     else {
    // //       this.displayItems[foundindex].reservedQuantity++;
    // //     }
    // //     // setTimeout(function() {
    // //     //   this.addtocart = "";
    // //     // }, 2000);
    // //     this.addtocart = "";
    // //   }
    // },
    // orders: {
    //   handler() {
    //     this.rentalPriceTotal = 0;
    //     this.bailTotal = 0;
    //     this.grandTotal = 0;
    //     for (var i = 0; i < this.orders.length; i++) {
    //       this.rentalPriceTotal =
    //         this.rentalPriceTotal +
    //         this.orders[i].rentalPrice * this.orders[i].reservedQuantity;
    //       this.bailTotal =
    //         this.bailTotal +
    //         this.orders[i].rentalPrice * this.orders[i].reservedQuantity;
    //     }
    //     this.grandTotal =
    //       this.grandTotal + this.rentalPriceTotal + this.bailTotal;
    //     console.log("this.grandTotal >>> ", this.grandTotal);
    //   },
    //   deep: true
    // }
  }
};
</script>

<style>
body {
  background-color: white !important;
}
input {
  width: 100%;
}
.theLabel {
  font-weight: bold;
  font-size: 12px;
}

.vs-col {
  flex-wrap: wrap;
}
.row-form {
  margin-bottom: 30px;
}
.form-label {
  font-weight: bold;
}

.grand-total {
  font-weight: bold;
  font-family: "mitr";
  font-size: 14px;
}
.grand-total-discount {
  font-family: "mitr";
  font-size: 12px;
}
.table-font {
  font-family: "mitr";
  font-size: 12px;
}

.the-black {
  color: #494949;
}
.forprint {
  font-size: 13px;
}

.the-blue {
  color: #4a90e2;
}
.the-green {
  color: #7ed321;
}
.the-red {
  color: #e95455;
}
.vx-card {
  padding-top: 0 !important;
  box-shadow: none !important;
}

.vx-card__body {
  padding: 0 !important;
}

.vue-barcode-element {
  width: 200px !important;
  height: 70px;
}

.vs-table--tbody-table .tr-values td {
  padding: 10px;
  padding-bottom: 0;
  /* padding-top: 5px; */
  overflow: hidden;
}

.customer_label {
  position: absolute;
  vertical-align: middle;
  text-align: center;
  width: 100px;
  /*height: 40px; */
  background-color: #549c04;
  color: white;
  margin-left: 20px;
  /* padding-top: 5px; */
  font-family: "mitr";
}

.staff_label {
  position: absolute;
  vertical-align: middle;
  text-align: center;
  width: 100px;
  /*height: 40px;*/
  background-color: #e02a6a;
  color: white;
  margin-left: 20px;
  padding-top: 5px;
  font-family: "mitr";
}

@media print {
  .pagebreak {
    clear: both;
    page-break-after: always;
  }
}
</style>
